import React, { FC, ReactNode, useEffect, useRef } from "react";
import { FieldCheckbox } from "../../fields/FieldCheckbox";
import { Alert } from "../../alerts/Alert";
import { TemplateAsComponent } from "../../TemplateAsComponent";
import {
  FormsTosLocalization,
  CustomText,
} from "../../../types/graphql/generated";
import { scrollTo } from "../../../helpers/scroll";
import { colors } from "../../../styles/variables";
import {
  StyledBody,
  StyledExternalLink,
  StyledTemplateAsComponentSmall,
  StyledTerms,
} from "./index.styled";

/**
 * NOTE - This component is present in both v2 and v3 as we transition everything to using v3.
 * Please be sure to make any changes to BOTH, so they're synchronized.
 */

interface TermsProps {
  children: ReactNode;
  terms: CustomText[];
}

interface TermsAndConditionsInfoProps extends TermsProps {
  type: "info";
}

interface TermsAndConditionsOptInProps extends TermsProps {
  hasGasExchangeItems?: boolean;
  localization?: FormsTosLocalization | null;
  submitFailed: boolean;
  type: "optin";
  valid: boolean;
}

type TermsAndConditionsProps =
  | TermsAndConditionsInfoProps
  | TermsAndConditionsOptInProps;

interface GetSortedTermsByLocation {
  location: "review" | "portal";
  terms: CustomText[];
  hasGasExchangeItems?: boolean;
}

export const getSortedTermsByLocation = ({
  location,
  terms,
  hasGasExchangeItems,
}: GetSortedTermsByLocation) => {
  return terms
    .filter((term) => {
      // if no gas exchange items don't show gas exchange terms
      if (!hasGasExchangeItems && term.type === "gasExchange") return false;
      return term.location === location;
    })
    .sort((a, b) => (a.position || 0) - (b.position || 0));
};

const getMap = (term: CustomText) => {
  return term.links.reduce<Record<string, ReactNode>>((links, link) => {
    const { linkValue, key, displayValue } = link;
    links[key] = (
      <StyledExternalLink href={linkValue} underline>
        {displayValue}
      </StyledExternalLink>
    );
    return links;
  }, {});
};

const Term: FC<{ term: CustomText; withCheckboxes?: boolean }> = ({
  term,
  withCheckboxes,
}) => (
  <StyledBody withCheckboxes={withCheckboxes}>
    {term.body.map((section, i) => {
      if (!i)
        return (
          <TemplateAsComponent
            template={section}
            map={getMap(term)}
            key={section}
          />
        );
      // This is a workaround to have the 2nd+ part of the terms be small text
      // as requested by SST. Since they are the only tenant that has a 2nd
      // part at this time, it doesn't affect any other tenants. If this
      // changes later on, we will need to extend tenant.brandConfig.legal
      // to indicate some styling so that this assumption is not made on the FE.
      return (
        <StyledTemplateAsComponentSmall
          template={section}
          map={getMap(term)}
          key={section}
        />
      );
    })}
  </StyledBody>
);

export const TermsAndConditions: FC<TermsAndConditionsProps> = (props) => {
  const { children, terms, type } = props;
  const termsRef = useRef<HTMLDivElement>(null);
  const submitFailed = "submitFailed" in props && props.submitFailed;
  const valid = "valid" in props && props.valid;

  useEffect(() => {
    if (submitFailed && !valid) scrollTo({ ref: termsRef });
  }, [submitFailed, valid]);

  if (type === "optin") {
    const { localization, submitFailed, valid } = props;

    const {
      errorText = "You must read and agree to the terms of service before proceeding",
    } = localization || {};

    return (
      <StyledTerms withCheckboxes ref={termsRef}>
        {submitFailed && !valid && (
          <Alert type="error" bold>
            {errorText}
          </Alert>
        )}
        {terms.map((term) => (
          <FieldCheckbox
            key={term.type}
            label={<Term term={term} withCheckboxes />}
            name={term.type}
            color={colors.gray600}
            fontSize="13px"
          />
        ))}
        {children}
      </StyledTerms>
    );
  }

  return (
    <StyledTerms>
      {terms.map((term) => (
        <Term term={term} key={term.type} />
      ))}
      {children}
    </StyledTerms>
  );
};
