import styled from "styled-components";
import { colors } from "../../../styles/variables";
import { TemplateAsComponent } from "../../TemplateAsComponent";
import { ExternalLink } from "../../links/ExternalLink";

export const StyledTerms = styled.div<{ withCheckboxes?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: ${colors.gray600};
  font-size: 13px;

  > div {
    ${({ withCheckboxes }) => withCheckboxes && `margin: 0;`}
  }
`;

export const StyledBody = styled.div<{ withCheckboxes?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  ${({ withCheckboxes }) => withCheckboxes && `margin-left: 5px;`}
`;

export const StyledExternalLink = styled(ExternalLink)`
  color: ${({ theme }) => theme.color.primary};
`;

export const StyledTemplateAsComponentSmall = styled(TemplateAsComponent)`
  font-size: 11px;
`;
